<template>
  <template v-if="filesStore.isLoadingItinerary">
    <div class="files-edit">
      <a-skeleton rows="1" active />
    </div>
    <div class="files-edit files-edit__border">
      <a-skeleton rows="1" active />
    </div>
    <div class="files-edit files-edit__border">
      <a-skeleton rows="1" active />
    </div>
  </template>
  <div class="files-edit" v-else>
    <a-steps :current="step" size="small" class="p-5 mb-5">
      <a-step :title="showMessage(0)" :description="t('files.label.choose_modify')" />
      <a-step
        :title="showMessage(1)"
        v-if="filesStore.getFileItinerary.send_communication"
        :description="t('files.label.communication_to_provider')"
      />
      <a-step :title="showMessage(2)" :description="t('files.label.complete_modification')" />
    </a-steps>

    <template v-if="step != 2">
      <div v-show="step == 0">
        <div class="d-flex justify-content-between align-items-center pt-5 pb-5">
          <div class="title">
            <font-awesome-icon :icon="['fa-solid', 'fa-pen-to-square']" class="text-danger" />
            {{ t('global.label.modify_service') }}
          </div>
          <div class="actions">
            <a-button
              v-on:click="returnToProgram()"
              class="text-600 btn-defaultbtn-back"
              :disabled="filesStore.isLoading"
              size="large"
            >
              {{ t('global.button.return_to_program') }}
            </a-button>
            <a-button
              v-on:click="goToCreateServiceTemporary()"
              class="text-600btn-temporary"
              danger
              :disabled="filesStore.isLoading"
              size="large"
            >
              <IconStopWatch color="#EB5757" width="1.3em" height="1.3em" />
              Crear servicio temporal
            </a-button>
          </div>
        </div>
        <service-selected />
        <service-search @onNextStep="nextStep" @onReturnToProgram="returnToProgram" />
      </div>
    </template>

    <div v-if="step == 1">
      <div
        class="d-flex justify-content-between align-items-center mt-5"
        v-if="filesStore.getFileItinerary.send_communication"
      >
        <div class="title">
          <font-awesome-icon icon="fa-solid fa-comment-alt" class="text-danger" />
          Comunicación al proveedor
        </div>
        <div class="actions">
          <a-button
            v-on:click="returnToProgram()"
            class="text-600"
            danger
            :disabled="filesStore.isLoading || filesStore.isLoadingAsync"
            size="large"
          >
            {{ t('global.button.return_to_program') }}
          </a-button>
        </div>
      </div>

      <service-merge
        :from="filesStore.getFileItinerary"
        :to="filesStore.getFileItinerariesServicesReplace"
        v-show="filesStore.getFileItinerary.send_communication"
        type="modification"
        ref="service"
        @onLoadReservation="loadReservation"
        @onPrevStep="prevStep"
        @onNextStep="nextStep"
      />
    </div>

    <div v-if="step == 2">
      <div class="mt-5 pt-5">
        <div class="text-center">
          <h2 class="text-danger text-800">Modificación de reserva exitosa</h2>
          <div class="my-5">
            <i class="bi bi-check2-circle text-success" style="font-size: 5rem"></i>
          </div>
        </div>

        <div
          class="box-completed bg-light py-3 px-5"
          v-for="(item, index) in reservation.to"
          :key="index"
        >
          <a-row type="flex" align="middle" justify="space-between" class="mx-5">
            <a-col class="me-4">
              <span class="text-danger">
                <b>Detalle de la reserva</b>
              </span>
            </a-col>
            <a-col flex="auto" v-if="reservation.notes_to != '' && reservation.notes_to != ''">
              <span class="bg-white px-3 py-2 bordered w-100 ant-row-middle">
                <span class="d-flex mx-1">
                  <i class="bi bi-pencil"></i>
                </span>
                <span class="text-danger text-600">Notas para el proveedor:</span>
                <span class="mx-2">{{ reservation.notes_to }}</span>
              </span>
            </a-col>
          </a-row>
          <a-row type="flex" align="top" justify="space-between" class="my-3 mx-5">
            <a-col>
              <p>
                <b>Número de File:</b>
                <span>{{ filesStore.getFile.fileNumber }}</span>
              </p>
              <p>
                <b>Nombre del File:</b>
                <span>{{ filesStore.getFile.description }}</span>
              </p>
              <p>
                <b>Nacionalidad:</b> <span>{{ showLanguage() }}</span>
              </p>
              <p>
                <b>Fecha de Reserva:</b>
                <span>{{ filesStore.getFile.dateIn }}</span>
              </p>
              <p>
                <b>E-mail del ejecutivo:</b>
                <span class="text-lowercase"
                  >{{ filesStore.getFile.executiveCode }}@limatours.com.pe</span
                >
              </p>
            </a-col>
            <a-col>
              <p>
                <b class="text-danger">Fechas:</b>
              </p>
              <p>
                <b>Check-in: </b> {{ item.search_parameters_services.date }}
                <!-- span class="text-danger">|</span> 15:00 -->
              </p>
            </a-col>
          </a-row>
          <a-row type="flex" align="middle" class="my-3 mx-5">
            <a-col :span="12">
              <p><b>Total Tarifa:</b> USD {{ item.price }}</p>
            </a-col>
          </a-row>
        </div>

        <div class="box-buttons mt-5">
          <a-row type="flex" justify="center" align="middle">
            <a-col>
              <a-button
                type="primary"
                class="px-4"
                v-on:click="returnToProgram()"
                default
                :disabled="filesStore.isLoading"
                size="large"
              >
                {{ t('global.button.close') }}
              </a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import {
    useFilesStore,
    useHaveInvoicesStore,
    useRevisionStagesStore,
    useStatusesStore,
  } from '@store/files';
  import { useLanguagesStore } from '@store/global';
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import ServiceSelected from '@/components/files/reusables/ServiceSelected.vue';
  import ServiceMerge from '@/components/files/reusables/ServiceMerge.vue';
  import ServiceSearch from '@/components/files/reusables/ServiceSearch.vue';
  import { useI18n } from 'vue-i18n';
  import IconStopWatch from '@/components/icons/IconStopWatch.vue';

  const { t } = useI18n({
    useScope: 'global',
  });

  const router = useRouter();
  const route = useRoute();
  const filesStore = useFilesStore();
  const service = ref(null);

  const languagesStore = useLanguagesStore();
  const statusesStore = useStatusesStore();
  const haveInvoicesStore = useHaveInvoicesStore();
  const revisionStagesStore = useRevisionStagesStore();
  const step = ref(0);
  const reservation = ref({});

  const showLanguage = () => {
    return languagesStore.find(filesStore.getFile.lang).label;
  };

  const showMessage = (_step) => {
    let message = t('global.label.finalized');

    if (step.value < _step) {
      message = t('global.label.on_hold');
    }

    if (step.value == _step) {
      message = t('global.label.in_progress');
    }

    return message;
  };

  const returnToProgram = () => {
    router.push({ name: 'files-edit', params: route.params });
  };

  const goToCreateServiceTemporary = () => {
    const currentId = router.currentRoute.value.params.id; // Obtenemos el :id desde la URL
    const currentServiceId = router.currentRoute.value.params.service_id; // Obtenemos el :service_id desde la URL
    localStorage.setItem('currentStep', '0');
    router.push({
      name: 'files-add-service-temporary',
      params: {
        id: currentId, // Usamos el id actual
        service_id: currentServiceId, // Usamos el service_id actual
      },
    });
  };

  onBeforeMount(async () => {
    const { id, service_id } = route.params;

    if (typeof filesStore.getFile.id == 'undefined') {
      await statusesStore.fetchAll();
      await haveInvoicesStore.fetchAll();
      await revisionStagesStore.fetchAll();

      await filesStore.getById({ id });
    }

    await filesStore.getFileItineraryById({ id, object_id: service_id });
    filesStore.finished();

    if (step.value == 0) {
      let itinerariesReplace = filesStore.getFileItinerariesServicesReplace.length;

      for (let i = 0; i < itinerariesReplace; i++) {
        filesStore.removeFileItineraryServiceReplace(i);
      }
    }
  });

  const nextStep = () => {
    step.value++;

    if (!filesStore.getFileItinerary.send_communication && step.value == 1) {
      filesStore.inited();
    }

    setTimeout(async () => {
      if (!filesStore.getFileItinerary.send_communication && step.value == 1) {
        const data = await service.value.processReservation(true);
        await loadReservation(data, true);
      }
    }, 100);
  };

  const prevStep = () => {
    step.value--;

    if (!filesStore.getFileItinerary.send_communication && step.value == 1) {
      step.value--;
    }
  };

  const loadReservation = async (data) => {
    console.log('RESPONSE: ', data);
    reservation.value = data.reservation;
    await filesStore.add_modify(data.params);
    nextStep();
  };
</script>
<style scoped lang="scss">
  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 25px;
  }

  .btn-temporary {
    width: auto;
    height: 45px;
    padding: 12px 24px 12px 24px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 14px;
    font-weight: 600 !important;
    color: #eb5757 !important;
    border: 1px solid #eb5757 !important;

    svg {
      margin-right: 10px;
    }

    &:hover {
      color: #eb5757 !important;
      background-color: #fff6f6 !important;
      border: 1px solid #eb5757 !important;
    }
  }

  .btn-back {
    width: auto;
    height: 45px;
    padding: 12px 24px 12px 24px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 14px;
    font-weight: 600 !important;
    color: #575757 !important;
    border: 1px solid #fafafa !important;

    &:hover {
      color: #575757 !important;
      background-color: #e9e9e9 !important;
      border: 1px solid #e9e9e9 !important;
    }
  }
</style>
